<template>
  <v-dialog
    v-model="isOpen"
    content-class="ImageGalleryDialog"
    fullscreen
  >
    <ImageGallery
      class="ImageGalleryDialog__gallery"
      :files="files"
      :current-file-ix="currentFileIx"
      carousel-height="100vh"
    >
      <template #footer.right="{ index, classes: cls }">
        <span
          :class="cls"
          v-text="getCaption(index)"
        />
      </template>
    </ImageGallery>

    <CommonIconButton
      absolute
      top
      right
      icon="mdi-close"
      color="white"
      @click="isOpen = false"
    />
  </v-dialog>
</template>

<script>
import { ImageGallery } from '@hexway/shared-front'

export default {
  name: 'ImageGalleryDialog',

  components: {
    ImageGallery,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    files: { type: Array, required: true }, // str[]
    initialFile: { type: String, default: null }, // file to show in the gallery from the start

    captions: { type: Array, default: () => [] }, // str[], indexes should match `files` prop
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    currentFileIx() {
      const { initialFile, files } = this
      if (!initialFile) return null
      const ix = files.indexOf(initialFile)
      return ix === -1 ? null : ix
    },
  },

  methods: {
    getCaption(ix) {
      return this.captions?.[ix] || ''
    },
  },
}
</script>

<style lang="sass">
.ImageGalleryDialog
  &__gallery
    height: 100%
    width: 100%
    overflow: hidden
</style>
